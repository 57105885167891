body.dark-theme {
  background-color: #222222;
}

.dark-theme .ui.inverted.menu {
  background-color: #2b2b2b;
}

.dark-theme .ui.grid > .row > .column {
  color: #ffffff;
}

/*
  disk-usage-progress
*/
.ui.progress.disk-usage-progress {
  margin-top: 0;
  border-radius: 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: 0;
  background-color: #ffffff;
}

.ui.progress.inverted.disk-usage-progress {
  border: 1px solid #444444;
  background-color: inherit;
}

.ui.progress.disk-usage-progress .bar {
  border-radius: 0;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  background-color: azure;
}

.ui.progress.inverted.disk-usage-progress .bar {
  border-right: 1px solid #444444;
  background-color: #2b2b2b;
}

.ui.progress.disk-usage-progress > .label {
  font-weight: normal;
  top: 0;
}
